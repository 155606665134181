.App {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.App-container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.App-header {
  background-color: #454a54 ;
  border-bottom: 1px solid #34363c;
  padding: 13px 10px;
  color: white;
}

.text-overflow {
    white-space: pre-wrap;
    overflow-wrap: anywhere;
}

.flex-row{
  display: flex;
  align-items: center;
}

.flex-1{
  flex: 1;
}

/*boostrap использует !important и это мешает dnd правильно задавать размеры*/
.w-100-ni {
    width: 100%;
}

.root-info {}
.root-info .name-row {
    font-size: 18px;
}
.root-info .period-row {
    font-size: 14px;
    opacity: 0.9;
}

.settings-row {
    display: flex;
    align-items: center;
}

.icon {
  vertical-align: middle;
  height: 25px;
}

.header-icon {
  height: 32px;
  margin-left: 15px;
  cursor: pointer;
}

.smooth-dnd-container.horizontal {
    height: 100%;
}

.App-intro {
  display: flex;
  font-size: small;
  width: 100%;
  overflow: auto;
  overflow-y: hidden;
  height: 100%;
}

.login {
    display: block;
    margin-top: 15vh;
    text-align: center;
}

.login .button {
    padding-top: 10px;
}

.error {
    display: none;
    color: #B22222;
    font-size: 16px;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@-moz-document url-prefix() {
    .App-container {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .App-intro {
        font-size: small;
        margin: 0;
        width: 100%;
        overflow: auto;
        flex: 1;
    }
}

.node-link {
  border: 2px dashed grey;
}

.settings {
    display: flex;
    overflow: auto;
    background: #e3e3e3;
    height: 100%;
    padding: 0 50px;
}

.settings h1 {
    margin: 15px 0;
    font-size: 18px;
    font-weight: bold;
}

.status {
    padding: 5px 0;
    cursor: pointer;
}

.status label {
    cursor: pointer;
}

.executor {
    padding: 10px 0;
}

.settings img {
    padding-right: 10px;
}

.settings input[type=checkbox] {
    vertical-align: middle;
    margin: 0 10px;
}

input[type=checkbox] {
    transform: scale(1.5);
}

.fancy-scroll::-webkit-scrollbar {
    width: 6px;
    height: auto;
}
.fancy-scroll > * {
    padding-right: 6px;
}
.fancy-scroll.fancy-scroll-horizontal::-webkit-scrollbar {
    height: 10px;
    width: auto;
}
.fancy-scroll.fancy-scroll-horizontal > * {
    padding-right: 0;
}

.fancy-scroll::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #aaa;
}

.App-intro.fancy-scroll.fancy-scroll-horizontal::-webkit-scrollbar {
    background-color: #3b3f46;
}
.App-intro.fancy-scroll.fancy-scroll-horizontal::-webkit-scrollbar-thumb {
    border: 2px solid #3b3f46;
}

.drag-lane {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    align-items: stretch;

    padding: 5px;
}

.drag-lane > .resizer {
    flex: 1 1 auto;
    height: 100% !important; /* override re-resizable style */
}

.card-root {
    display: flex;
    flex-direction: column;

    flex: 1 1 auto;
}

.card-title {
    color: black;
}

/* Кастомный css, чтобы title выглядел лучше */
.card-title p {
    margin: 10px 0;
}
.card-title p:first-child {
    margin-top: 0;
}
.card-title p:last-child {
    margin-bottom: 0;
}
.card-title img {
    object-position: center;
    object-fit: scale-down;
    max-width: 100%;
    height: auto;
}

.card-tooltip {
    max-width: 640px;
    opacity: 1 !important;
}
