.comPlainTextContentEditable {
    -webkit-user-modify: read-write-plaintext-only;
}

.comPlainTextContentEditable--has-placeholder::before {
    content: attr(placeholder);
    opacity: 0.5;
    color: inherit;
    cursor: text;
}

.react_trello_dragClass {
    transform: rotate(3deg);
}

.react_trello_dragLaneClass {
    transform: rotate(3deg);
}

.smooth-dnd-container {
    display: flex;
    flex: 1 1 auto;
}

.smooth-dnd-container.vertical {
    flex-direction: column;
    align-items: flex-end;
}

.smooth-dnd-container.horizontal {
    flex-direction: row;
}

.smooth-dnd-draggable-wrapper {
    display: flex !important;
}

@-moz-document url-prefix() {
    .smooth-dnd-container.vertical {
        display: inline-flex;
        flex-direction: column;
        align-items: flex-end;
        margin-right: 15px;
        margin-left: 5px;
    }
}

.smooth-dnd-ghost.animated{
    transition: all ease-in-out;
}

.smooth-dnd-draggable-wrapper.animated{
    transition: transform ease;
}