html {
    overflow: auto;
    margin: 0;
    padding: 0;
    min-height: 100%;
    height: 100%;
    max-height: 100%;
}

body {
    font-family: 'Open Sans', sans-serif;
    line-height: normal;
    background-color: #5a606d;
    margin: 0;
    padding: 0;
    min-height: 100%;
    height: 100%;
    max-height: 100%;
}

#root {
    height: 100%;
}
