.card-details-container h3 {
    margin: 15px 0 0;
    font-size: 15px;
    font-weight: bold;
}

.card-details-pre-property {
    overflow-x: auto;
    max-width: 100%;
    /*white-space: pre-line;*/
}

.card-details-property img {
    object-position: center;
    object-fit: scale-down;
    max-width: 100%;
    height: auto;
}